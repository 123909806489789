<template>

<div
              class="card border-radius w-100"
              :class="[plan.name=='Pro'?'pro-bg-color':'']"
              style="min-height: 400px;cursor:pointer;border-color: #EAECF0;"
              
            >       
            
           

              <div class="d-flex card-body flex-column flex-grow-1 ">
                <div class="PriceTopSection">
                  <div class="priceSection">
                    <div
                      class="title d-flex  justify-content-between align-items-start"
                      :style="{paddingBottom: plan.name == 'Plus'?'0px':'8px'}"
                    >
                      <div class="m-0"  :class="[plan.name=='Pro'?'bg-pro-color':'']">{{ plan.name }} </div>
                      <div class="mostPopularContainer" v-if="plan.name == 'Plus'">
                    <span  :class="[plan.name=='Pro'?'bg-pro-color':'']">Most Popular</span>
                </div>
                    </div>

                  </div>
                </div>
                
                <div class="midSection h-100">
                  <div class="d-flex align-items-end my-3" style="gap:4px">
                    <div class="price m-0"  :class="[plan.name=='Pro'?'bg-pro-color':'']" v-if="plan.name !== 'Free'">${{ mode == 'yearly' ? plan.payment_amount/12 : plan.payment_amount }}</div>
                    <div v-if="plan.name == 'Free'" class="subText m-0"  :class="[plan.name=='Pro'?'bg-pro-color':'']"
                      >Free</div
                    >
                    <div v-else class="subText m-0 "  :class="[plan.name=='Pro'?'bg-pro-color':'']"> per month</div>
                  </div>

                    <div class="midDescription "   style="min-height: 100% !important;">
                        <p class="m-0" :class="[plan.name=='Pro'?'bg-pro-color':'']">{{plan.description}}</p>
                    </div>
                    <div class="buttonSection mt-3">
                  <!-- :style="getBtnStyles(plan)" -->
<button
  :disabled="isBtnDisabled(plan)"
  class="btn w-100 px-3 py-2 priceButton"
   :class="[plan.name=='Pro'?'bg-pro-button':'']"
  @click.stop="openPlan(plan)"
  v-if="validatePlan(plan, mode)"
>
  <span class="startBtn">{{
    user.planId == plan.id ? "Current Plan" : "Upgrade"
  }}</span>
</button>

<span v-else :id="`monthly-${plan.id}`" v-b-tooltip.hover>
  <!-- :style="getDisabledBtnStyles(plan)" -->
  <button
  :class="[plan.name=='Pro'?'bg-pro-button':'']"
    :disabled="!validatePlan(plan, mode)"
    class="btn w-100 px-3 py-2 priceButton"
 
    style="pointer-events: none !important"
  >
    <span class="startBtn">{{
      user.planId == plan.id ? "Current Plan" : "Upgrade"
    }}</span>
  </button>
  <b-tooltip
    offset="5"
    variant="secondary"
    :target="`monthly-${plan.id}`"
    >Please contact
    <span
      style="cursor: pointer"
      @click="openSupport"
      class="font-weight-bold"
      >support
    </span>
    for change in plan</b-tooltip
  >
</span>
</div>
</div>
</div>
               <div class="border-line">
               <div class="card-body ">

                  <div class=" featureContainer" >

                  
                  <div class="midFeatures mb-2">
                    <!-- Engagements -->
                    <div class="">
                      <div>
                        <p class="m-0 mb-2 d-flex" :class="[plan.name=='Pro'?'bg-pro-color':'']">
                            <span class=" d-flex align-items-center justify-content-center mr-2"  >
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                         
                          <span :class="[plan.name=='Pro'?'bg-pro-color':'']"  class="pr-2">{{ convertToK(plan.plan_quota.engagements) }} </span>
                          Engagements monthly
                        </p>
                      </div>
                    </div>
                    <!-- Engagements End-->

                    <div class="">
                      <p class="m-0 mb-2 d-flex">
                        <span class=" d-flex align-items-center justify-content-center mr-2"  >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                        <span  :class="[plan.name=='Pro'?'bg-pro-color':'']" class="" v-if="plan.plan_quota.quizzes <= 1"
                          >{{ plan.plan_quota.quizzes }} Quiz</span
                        >
                        <span  :class="[plan.name=='Pro'?'bg-pro-color':'']" class="" v-else
                          >{{ plan.plan_quota.quizzes }} Quizzes</span
                        >
                      </p>
                    </div>
                    <!-- Quiz End-->
                    <div class="">
                      <p
                       :class="[plan.name=='Pro'?'bg-pro-color':'']"
                        class="m-0 mb-2 d-flex"
                        v-if="plan.plan_quota.questions !== 0"
                      >
                      <span class=" d-flex align-items-center justify-content-center mr-2"  >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                        Up to {{ plan.plan_quota.questions }} Questions
                      </p>
                      <p
                        v-else
                        class=" m-0 mb-2 d-flex"
                         :class="[plan.name=='Pro'?'bg-pro-color':'']"
                      >
                      <span  class=" d-flex align-items-center justify-content-center mr-2"  >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                        Unlimited Questions
                      </p>
                    </div>
                    <!-- Question End-->
                    <div class="">
                      <p class="m-0 mb-2 d-flex"  :class="[plan.name=='Pro'?'bg-pro-color':'']" v-if="plan.plan_quota.products !== 0">
                        <span class=" d-flex align-items-center justify-content-center mr-2"  >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                        Up to
                        <span class="px-1">{{ plan.plan_quota.products }} </span>Products
                      </p>
                      <p
                        v-else
                        class="m-0 mb-2 d-flex"
                         :class="[plan.name=='Pro'?'bg-pro-color':'']"
                      >
                      <span class=" d-flex align-items-center justify-content-center mr-2"  >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                        Unlimited Products
                      </p>
                    </div>
                    <!-- Products End-->
                  </div>

                  <div class="midCheckFeatures" >
                    <div v-for="(check, index) in planFeature" :key="index">
                      <p class="m-0 mb-2 d-flex"  :class="[plan.name=='Pro'?'bg-pro-color':'']">
                        <span class=" d-flex align-items-center justify-content-center mr-2"  >
                              
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_48_2346)">
<rect width="24" height="24" rx="12" :fill="plan.name=='Pro'?'#553E6A':'#F3E7FF'"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" :fill="plan.name=='Pro'?'#B46DFB':'#9140E1'"/>
</g>
<defs>
<clipPath id="clip0_48_2346">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>

                            </span>
                        {{ check.text }}
                      </p>
                    </div>
                  </div>
                </div>  
              <div class="endSection pb-3 border-line d-none" style=""  :class="[plan.name=='Pro'?'bg-pro-color':'']">
                <div class="">
                             <p class=" mr-2" ><span class="" style="font-weight:700;" :style="`font-weight:900;color:${getTextColor(plan)}`">{{plan.plan_quota.templates}}</span> Templates</p>
                             <p class=" mr-2" ><span class="" style="font-weight:700;" :style="`font-weight:900;color:${getTextColor(plan)}`">{{plan.plan_quota.analytics}}</span>  Analytics</p>
                            
                         </div>
              </div>
                </div>

    
              </div>
            </div>
         

</template>
<script>

export default {

  props: ["plan", "user", "mode"],
  data() {
    return {
      planFeature: [
        {
          text: "Integrations",
        },
        {
          text: "Jump Logics",
        },
      ],
    };
  },
  methods: {
  convertToK(number) {
    if (number >= 1000) {
        var formattedNumber = (number / 1000).toFixed(1);
        return parseInt(formattedNumber) + 'K';
    } else {
        return number;
    }
},
    getButtonText() {
      return `Get Started`;
    },
    openSupport() {
      let url = "https://support.quizell.com/";
      window.open(url, "_blank");
    },

    changeSelectedBtn(item) {
      this.selectedBtn = item;
    },

    getTextColor(plan) {
      let style = ` color:${plan.color};`;
      return style;
    },
    getBGColor(plan) {
      let style = ` background:${plan.color}33;`;
      return style;
    },

    openPlan(plan) {
      this.$emit("openPlanFunc", plan);
    },

    validatePlan(plan, planType) {
      let planTypeMode = "";

      let currentPlanId = this.user.planId;

      if (currentPlanId == 7 || currentPlanId == 8 || currentPlanId == 9) {
        planTypeMode = "yearPlan";
      }
      if (currentPlanId == 4 || currentPlanId == 5 || currentPlanId == 6) {
        planTypeMode = "monthlyPlan";
      }
      if (currentPlanId == 1) {
        planTypeMode = "freePlan";
      }
      let isValid;
      if (planTypeMode == "freePlan") {
        isValid = true;
      } else {
        if (planType == "yearly") {
          if (planTypeMode == "monthlyPlan") {
            isValid = true;
          } else {
            if (currentPlanId <= plan.id) {
              isValid = true;
            } else {
              isValid = false;
            }
          }
        } else {
          if (planTypeMode == "yearPlan") {
            isValid = true;
          } else {
            if (currentPlanId <= plan.id) {
              isValid = true;
            } else {
              isValid = false;
            }
          }
        }
      }

      return isValid;
    },

    isBtnDisabled(plan) {
      if (Object.entries(this.user).length !== 0) {
        if (plan.id == this.user.plan_id) return true;
        return false;
      } else {
        return false;
      }
    },

    getBtnStyles(plan) {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:${plan.bg_color};color:#ffffff;opacity:0.8;`;

      return style;
    },
    getDisabledBtnStyles() {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:#7C8290;color:#ffffff;opacity:0.8;pointer-events:none !important;`;

      return style;
    },
  },
};
</script>

<style scoped>
   .PricingCard {
    border-radius: 20px;
background: #FFF;
position: relative;
box-shadow: 0px -2px 16px 0px rgba(77, 27, 126, 0.10);

}
.mostPopularContainer{
   background: #E6F5FA;
padding: 4px 12px 4px 12px;
border-radius: 16px;
color: var(--secondary-color);
font-size: 14px;
font-weight: 500;
text-align: center;
animation: blink 2s infinite;

}


 .PricingDiv .PriceTopSection {
  color: #ffffff;
  font-family: "Poppins";
}
  .title{
  color: #667085;
font-family: Poppins;

font-size: 20px;
font-weight: 500;
line-height: 28px;
text-align: left;

}

 .midSection .price {
  color: #292D35;
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: -0.02em;
    text-align: left;

}
 .midSection .subText {
color:  #667085;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;

}

 .midSection .priceText span {
  color: #7C8290;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
}

 .buttonSection a {
  background: transparent;
  border: 1.02182px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
 .buttonSection a:hover {
  background: #ffffff;
  color: #042542;
}


.midDescription p{
    color:  #667085;
    
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

}


.featureContainer{
    height: 100%;
}
.border-line:before{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #EAECF0;
}
 .midFeatures p {
  color:  #7C8290;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
 /* .midFeatures p span {
  font-weight: 700;
} */
 .midCheckFeatures p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7c8290;
}

 .endSection p {
    font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  color: #1f1f28;
}

 

.Pricing-Comp-Main .buttonSection .priceButton:hover {
  opacity: 1 !important;
}

 .most-popular {
  background: #0FD452;
  border-radius: 5.70473px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
   padding: 6px 4px;
  color: #ffffff;
}

 .btn-group {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
  border-radius: 100px;
  color: #4d1b7e;
}
 .btn-group p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  text-align: center;

  color: #4d1b7e;
}
 .btn-group:hover {
  background: #4d1b7e0d;
}
 .btn-group p:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 0px 100px 100px 0px;
}
 .btn-group .yearly:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 100px 0px 0px 100px;
}

 .btn-group .selectedBtn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;

  border-radius: 100px;
}
 .btn-group .selectedBtn:hover {
  color: #ffffff;
}
 .topSection .title {
  color: #292929;
font-family: Poppins;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
}
 .topSection .subText {
  color: #222;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 150%;
text-align: center;
  padding: 0 5%;
}
 .topSection .subText span{
  color: #6B3EDA;
font-weight: 700;
}
.pro-bg-color{
box-shadow: 0px 12px 16px -4px #10182814;
background: #101828;
}
.bg-pro-color{
  color: white !important;;
}
.bg-pro-button{
  background:var(--white)!important;
  color:var(--primary-color)!important
}
.bg-pro-button:hover{
  color:var(--white)!important;
  background:var(--primary-color)!important
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


.priceButton{
  padding: 12px 18px 12px 18px;
    border-radius: 99px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: 0.3s ease all;
}
.priceButton:disabled{
  pointer-events: none;
  opacity: 0.8;
  cursor:not-allowed;
  color: var(--white);
  background: var(--primary-color);
}
.priceButton:not(:disabled):hover{
  color: var(--white);
  background: var(--primary-color);
}
</style>
